import React, { useEffect } from 'react';
import { graphql } from 'gatsby';

import SEO from '../../components/seo';
import Hero from './hero/hero';
import Feature from './feature/feature';
import RegisterPanel from './registerpanel/registerpanel';
import Footer from './footer/footer';

import ThrottleStyles from './ThrottleStyles';
import getUrlStrings from '../../utils/getUrlStrings';

const Fullthrottle = props => {
  const UTMs = getUrlStrings(props.location.search);

  const utmObj = {
    utm_campaign: UTMs.utm_campaign || '',
    utm_source: UTMs.utm_source || '',
    utm_medium: UTMs.utm_medium || '',
    utm_term: UTMs.utm_term || '',
    utm_content: UTMs.utm_content || '',
    google_click_id: UTMs.gclid || '',
  };

  useEffect(() => {
    // eslint-disable-next-line valid-typeof
    if (typeof window !== undefined) {
      window.sessionStorage.setItem('utms', JSON.stringify(utmObj));
    }
  }, [utmObj]);

  const {
    page: {
      seo,
    },
  } = props.data;
  return (
    <ThrottleStyles>
      <SEO seo={seo} />
      <Hero child={false} />
      <Feature />
      <RegisterPanel child={false} />
      <Footer />
    </ThrottleStyles>
  );
};

// export const query = graphql`
//   query FullthrottleQuery {
//     page: datoCmsWebsitePage(internalName: { eq: "Homepage" }) {
//       seo: seoMetaTags {
//         ...GatsbyDatoCmsSeoMetaTags
//       }
//     }
//   }
// `;

export default Fullthrottle;
